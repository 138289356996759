import { useEffect, useState } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditAyurvedaDepartureConsultation.scss'
import { ADD, AyurvedaDepartureConsultationField, AyurvedaDepartureConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import { getConsultationNotes } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { useDispatch, useSelector } from 'react-redux'
import { getDateObjectFromTimeString, parseJSON } from '../../../../../utils/helpers/universalFunctions'
import { TimeSelector } from '../../../../../components/TimeSelector/TimeSelector'
import { ReactComponent as Add } from '../../../../../assets/images/prelimAdd.svg'
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import { CircularProgress } from '@mui/material'

const Medicines = ({setKey, removeObjective, content})=>{
    return (
        <div key={content.data?.other_recommendations?.medicine?.length}>
            {
                content.data?.other_recommendations?.medicine?.map((item, index)=>{
                    return (
                        <div className="row mb-2" key={index}>
                            <div className='col-4'>
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["other_recommendations", "medicine", index, "medicines"], value)}
                                    value={content?.data?.other_recommendations?.medicine?.[index]?.medicines || ''}/>
                            </div>
                            <div className='col-4'>
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["other_recommendations", "medicine", index, "dosage"], value)}
                                    value={content?.data?.other_recommendations?.medicine?.[index]?.dosage || ''}/>
                            </div>
                            <div className='col-4 align-items-center d-flex'>
                                <div className="flex-grow-1 me-3">
                                    <TextArea
                                        row={2}
                                        placeholder={"Enter "}
                                        onChange={value =>setKey(["other_recommendations", "medicine", index, "frequency"], value)}
                                        value={content?.data?.other_recommendations?.medicine?.[index]?.frequency || ''}/>
                                </div>
                                <div className='remove-btn' onClick={()=>removeObjective(index)}>
                                    <RemoveBtn/>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}


export const AddEditAyurvedaDepartureConsultation = ({content, setContent, formType,  guestId, userProgramId, guestName, uploadComponent})=>{
    const dispatch = useDispatch()
    const {consultationNotesData, loading} = useSelector(state=> state.consultationNotesReducer)

  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.other_recommendations.medicine.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.other_recommendations.medicine = [...updatedData.data.other_recommendations.medicine, AyurvedaDepartureConsultationJSON.data.other_recommendations.medicine[0]]
            return updatedData;
        })
    }


    useEffect(()=>{
        if(guestId && userProgramId && formType===ADD){
            dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Ayurveda Arrival Consultation"}))
        }
      },[])

    useEffect(()=>{
        let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail);
        if(notes_detail && formType===ADD){ //have dummy JSON
        setContent(prev=>{
            let newData= {...prev};
            newData.data.programme_and_treatment_plan = {
                "dosha_prakriti": notes_detail?.data?.summary_assessment_treatment_plan?.dosha_prakriti,
                "dosha_vikruiti": notes_detail?.data?.summary_assessment_treatment_plan?.dosha_vikruiti,
                "objectives": notes_detail?.data?.summary_assessment_treatment_plan?.objectives
            }

            for (let key in notes_detail.data.ayurvedic_health_assessment) {
                newData.data.health_assessment[key].arrival_reading = notes_detail?.data.ayurvedic_health_assessment[key]
            }
            return newData;
        })
        }
    },[consultationNotesData])

    return (
      <div className='ayurveda-departure-consultation-form'>
        {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <>
            <div className="d-flex py-4 mb-2 border-top rounded justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Conducted By</div>
                    <TextField
                        width={'300px'}
                        type={'text'}
                        placeholder='Enter name' 
                        value={content?.conducted_by || ''} 
                        onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Date</div>
                    <DatePicker
                    value={content.date ? new Date(content.date) : ''}
                    onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                    width={'300px'}
                    />
                </div>
                </div>
                <div className=" w-100 rounded mb-4">
                    <div className="fw-semi-bold">
                        Dear {guestName},
                    </div>
                    Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Ayurvedic Physician during post-stay online follow-ups.  
                </div>
                <div className="border w-100 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    PROGRAMME & TREATMENT PLAN
                    </div>
                    <div className='p-3'>
                        <div className='d-flex mb-2'>
                            <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Prakriti:</div>
                            <div className="text-break white-space">{content.data?.programme_and_treatment_plan?.dosha_prakriti || '-'}</div>
                        </div>
                        <div className='d-flex mb-2'>
                            <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Vikruti (to be balanced):</div>
                            <div className="text-break white-space">{content.data?.programme_and_treatment_plan?.dosha_vikruiti || '-'}</div>
                        </div>
                        {
                            content?.data?.programme_and_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className='border mt-4 ' key={index}>
                                        <div className='fw-semibold text-secondary p-2 text-center bg-light text-uppercase'> Objective {(index+1)?.toString()?.padStart(2,0)}: </div>
                                        <table className='p-2 w-100'>
                                            {
                                                AyurvedaDepartureConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <tr className='d-flex' key={subIndex}>
                                                        <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}</td>
                                                        <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.programme_and_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                    </tr>
                                                    ))
                                            }
                                        </table>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded healthassessment">
                    <div className="heading py-3">
                    HEALTH ASSESSMENT
                    </div>
                    <div className='p-3'>
                        <div className="row fw-semibold pb-2 border-bottom w-100 mb-3" >
                            <div className="mt-2 col-3">Parameter</div>
                            <div className='col-4'>
                                On Arrival
                            </div>
                            <div className='col-5'>
                                On Departure
                            </div>
                        </div>
                            {
                                AyurvedaDepartureConsultationField?.healthAssessment?.map((item, index)=>{
                                    return (
                                        <div className="row w-100 mb-3" key={index}>
                                            <div className="title mt-2 col-3">{item.label}</div>
                                            <div className='col-4'>
                                                {content?.data?.health_assessment?.[item.key]?.arrival_reading || '-'}
                                            </div>
                                            <div className='col-5'>
                                                <div className="data-input">
                                                    <TextArea
                                                        row={3}
                                                        placeholder={"Remarks "}
                                                        onChange={value =>setKey(["health_assessment", item.key, "value"], value)}
                                                        value={content?.data?.health_assessment?.[item.key]?.value || ''}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded systematic">
                    <div className="heading py-3">
                        RECOMMENDATIONS AND FOLLOW UPS
                    </div>
                    <div className='p-3'>
                        <div className="row fw-semibold pb-2 border-bottom mb-3" >
                            <div className="mt-2 col-3">Daily Routine</div>
                            <div className='col-4'>
                                Time
                            </div>
                            <div className='col-5'>
                                Frequency
                            </div>
                        </div>
                            {
                                AyurvedaDepartureConsultationField?.recommendationsAndFollowUps?.map((item, index)=>{
                                    return (
                                        <div className="row w-100 mb-2" key={index}>
                                            <div className="title mt-2 col-3">{item.label}</div>
                                            <div className='col-4 pt-2'>
                                                <TimeSelector
                                                    width={'100%'}
                                                    ampm={true}
                                                    time={getDateObjectFromTimeString(content?.data?.recommendations_and_follow_ups?.[item.key]?.time) || ''}
                                                    setTime={(value)=>setKey(["recommendations_and_follow_ups", item.key, "time"], moment(new Date(value)).format('hh:mm A')) }
                                                    
                                                />
                                            </div>
                                            <div className='col-5'>
                                                <div className="data-input">
                                                    <TextArea
                                                        row={2}
                                                        placeholder={"Remarks "}
                                                        onChange={value =>setKey(["recommendations_and_follow_ups", item.key, "frequency"], value)}
                                                        value={content?.data?.recommendations_and_follow_ups?.[item.key]?.frequency || ''}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>

                <div className="border w-100 rounded mt-5 ashtavidha">
                    <div className="heading py-3">
                        OTHER RECOMMENDATIONS
                    </div>
                    <div className='p-3'>
                        <div className="border-bottom fw-semibold mb-3 text-uppercase pb-3 text-center">Dietary Recommendations</div>
                        {
                            AyurvedaDepartureConsultationField?.otherRecommendations?.dietaryRecommendation?.map((item, index)=>{
                                return (
                                    <div className="data-row" key={index}>
                                        <div className="title mt-2">{item.label}</div>
                                        <div className='data-input'>
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["other_recommendations", "dietary", item.key], value)}
                                                value={content?.data?.other_recommendations?.dietary?.[item.key] || ''}/>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="border-bottom border-top fw-semibold mt-5 mb-3 text-uppercase py-3 text-center">Physical & Mental Activity</div>
                        {
                            AyurvedaDepartureConsultationField?.otherRecommendations?.physicalAndMentalActivity?.map((item, index)=>{
                                return (
                                    <div className="data-row" key={index}>
                                        <div className="title mt-2">{item.label}</div>
                                        <div className='data-input'>
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["other_recommendations", "physical_mental", item.key], value)}
                                                value={content?.data?.other_recommendations?.physical_mental?.[item.key] || ''}/>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="border-bottom border-top fw-semibold mt-5 text-uppercase py-3 text-center">Medicines Recommended</div>
                        <div className="border-bottom text-muted fw-semibold mb-3 py-3 text-center row">
                            <div className="col-4">Medicine</div>
                            <div className="col-4">Dosage</div>
                            <div className="col-4">Frequency</div>
                        </div>

                        <Medicines 
                            content={content}
                            removeObjective={removeObjective}
                            setKey={setKey}
                        />
                        <divs>
                            <div className='cursor-pointer mx-auto' style={{width: 'fit-content'}} onClick={()=>handleAddMore()}>
                                <Add/>
                            </div>
                        </divs>

                    </div>
                </div>

                <div className="border w-100 mt-5 mb-3 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    FOLLOW UP PROGRAMME
                    </div>
                    <div className=' '>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">{'Within 30 days of departure'}</div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.programme_and_treatment_plan?.dosha_prakriti || '-'}</div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.programme_and_treatment_plan?.dosha_vikruiti || '-'}</div>
                        </div>
                    </div>
                </div>
            </>
            <div className="w-50">
            {uploadComponent}
            </div>
          </>
        }
      </div>
    )
}