import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditYogaArrivalConsultation.scss'
import {YogaArrivalConsultationField, YogaArrivalConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import CheckBox from '../../../../../components/CheckBox/CheckBox'
import RadioButton from '../../../../../components/RadioButton/RadioButton'

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="border w-100 rounded mt-5 summary" key={content?.data?.summary_assessment_treatment_plan?.objectives?.length}>
            <div className="heading py-3">
                SUMMARY ASSESSMENT AND TREATMENT PLAN
            </div>
            <div className='p-3'>
                <div className="sub-heading py-4">
                    Primary objectives for improvement
                </div>
                <div className="d-flex flex-wrap align-items-stretch justify-content-start">
                    {
                        content?.data?.summary_assessment_treatment_plan?.objectives?.map((subitem, subindex)=> {
                            return (
                                <div className="objective-box p-2" key={subindex}>
                                    <div className='border rounded'>
                                        <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                            Objective {(subindex+1)?.toString()?.padStart(2, 0)}
                                            <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                                <RemoveBtn/>
                                            </div>
                                        </div>
                                        <div className="p-3">
                                        {
                                            YogaArrivalConsultationField?.objectives?.map((item, index)=>{
                                                return (
                                                    <div className="data-row d-flex" key={index}>
                                                        <div className="fw-semi-bold mt-2" style={{width: '210px'}}>{item.label}</div>
                                                        <div className='data-input'>
                                                            <TextArea
                                                                placeholder={"Enter "}
                                                                onChange={value =>setKey(["summary_assessment_treatment_plan", "objectives", subindex, item.key], value)}
                                                                row={2}
                                                                value={content?.data?.summary_assessment_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    
                    <div className="p-2 w-50 "  onClick={handleAddMore}>
                        <div className="add-more ">
                            Add More Objectives
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export const AddEditYogaArrivalConsultation = ({content, setContent, type,  guestId, userProgramId, formType, uploadComponent})=>{
  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.summary_assessment_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.summary_assessment_treatment_plan.objectives = [...updatedData.data.summary_assessment_treatment_plan.objectives, YogaArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }



    return (
      <div className='yoga-arrival-consultation-form'>
          <>
          <div className="d-flex py-2 mb-3 rounded justify-content-between">
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Conducted By</div>
                <TextField
                    width={'300px'}
                    type={'text'}
                    placeholder='Enter name' 
                    value={content?.conducted_by || ''} 
                    onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                  />
              </div>
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Date</div>
                <DatePicker
                  value={content.date ? new Date(content.date) : ''}
                  onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                  width={'300px'}
                />
              </div>
            </div>
            <div className="border w-100 rounded p-3">
                <div className="data-row">
                    <div className="title mt-2">Knowledge/experience level of yoga and pranayama. If yes, frequency of practice.</div>
                    <div className='data-input'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["experience_in_yoga_pranayama"], value)}
                            value={content?.data?.experience_in_yoga_pranayama || ''}/>
                    </div>
                </div>
                <div className="data-row">
                    <div className="title mt-2">Knowledge/experience level of meditation/mindfullness. If yes, frequency of practice.</div>
                    <div className='data-input'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["experience_in_meditation"], value)}
                            value={content?.data?.experience_in_meditation || ''}/>
                    </div>
                </div>
                <div className="data-row">
                    <div className="title mt-2">Forms of activity for physical fitness. Frequency</div>
                    <div className='data-input'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["forms_of_activity"], value)}
                            value={content?.data?.forms_of_activity || ''}/>
                    </div>
                </div>
                <div>
                    <div className="title text-uppercase mt-5 mb-3">
                        Specific Areas of Discomfort:
                    </div>
                    <div className='row mb-3'>
                        <div className="col-2"></div>
                        <div className="col-5 text-muted fw-semi-bold">Frequency of discomfort</div>
                        <div className="col-5 text-muted fw-semi-bold">Known reasons</div>
                    </div>
                    {
                        YogaArrivalConsultationField?.areaOfDiscomfort?.map((item, index)=>{
                            return (
                                <div className='row'>
                                    <div className="col-2 mt-2 fw-semi-bold">
                                        <div className="d-flex align-items-center">
                                            <span style={{width: '90px'}} className="d-inline-block me-2 mt-2">{item.label}</span>
                                            <span className="d-inline-block">
                                                <CheckBox
                                                className={'mt-2 pt-1'}
                                                    initialchecked={content?.data?.area_of_discomfort?.[item.key]?.value==='Yes'}
                                                    onChange={(e)=>{setKey(["area_of_discomfort", item.key, "value"], content?.data?.area_of_discomfort?.[item.key]?.value==='No' ? "Yes": "No")}}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="col-5">
                                        <TextArea
                                            row={2}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["area_of_discomfort", item.key, "frequency"], value)}
                                            value={content?.data?.area_of_discomfort?.[item.key]?.frequency || ''}/>
                                    </div>
                                    <div className="col-5">
                                        <TextArea
                                            row={2}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["area_of_discomfort", item.key, "reasons"], value)}
                                            value={content?.data?.area_of_discomfort?.[item.key]?.reasons || ''}/>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <div>
                    <div className="title text-uppercase mt-5 mb-3">
                    Yogic Assessment:
                    </div>
                    <div className='row mb-3'>
                        <div className="col-4"></div>
                        <div className="col-2  text-muted fw-semi-bold">Beginner</div>
                        <div className="col-2 text-muted fw-semi-bold">Intermediate</div>
                        <div className="col-2 text-muted fw-semi-bold">Advanced</div>
                    </div>
                    {
                        YogaArrivalConsultationField?.yogicAssessment?.map((item, index)=>{
                            return (
                                <div className='row'>
                                    <div className="col-4 mt-3 fw-semi-bold">{item.label}</div>
                                    <div className="col-2 text-center mt-3">
                                        <RadioButton
                                            name={item.key}
                                            value={"beginner"}
                                            checked={content?.data?.yogic_assessment?.[item.key]==="beginner"}
                                            onChange={(e)=>{setKey(["yogic_assessment", item.key], e.target.value)}}
                                        />
                                    </div>
                                    <div className="col-2 text-center mt-3">
                                        <RadioButton
                                            name={item.key}
                                            value={"intermediate"}
                                            checked={content?.data?.yogic_assessment?.[item.key]==="intermediate"}
                                            onChange={(e)=>{setKey(["yogic_assessment", item.key], e.target.value)}}
                                        />
                                    </div>
                                    <div className="col-2 text-center mt-3">
                                        <RadioButton
                                            name={item.key}
                                            value={"advanced"}
                                            checked={content?.data?.yogic_assessment?.[item.key]==="advanced"}
                                            onChange={(e)=>{setKey(["yogic_assessment", item.key], e.target.value)}}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <div>
                    <div className="title text-uppercase mt-5 mb-1">
                    Breathing Pattern:
                    </div>
                    {
                        YogaArrivalConsultationField?.breathingPatterns?.map((item, index)=>{
                            return (
                                <div className='row'>
                                    <div className="col-4 mt-3 fw-semi-bold">{item.label}</div>
                                    <div className="col-2 mt-3">
                                        <RadioButton
                                            name={item.key}
                                            label={"Yes"}
                                            value={"Yes"}
                                            checked={content?.data?.breathing_pattern?.[item.key]==="Yes"}
                                            onChange={(e)=>{setKey(["breathing_pattern", item.key], e.target.value)}}
                                        />
                                    </div>
                                    <div className="col-2 mt-3">
                                        <RadioButton
                                            name={item.key}
                                            label={"No"}
                                            value={"No"}
                                            checked={content?.data?.breathing_pattern?.[item.key]==="No"}
                                            onChange={(e)=>{setKey(["breathing_pattern", item.key], e.target.value)}}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='mt-3 row'>
                        <div className='fw-semi-bold col-4'> Inhale : Exhale Ratio <br/> <span className='text-muted'>(after few rounds of normal breathing)</span></div>
                        <div className="col-8">
                            <TextArea
                                row={1}
                                placeholder={"Enter "}
                                onChange={value =>setKey(["breathing_pattern", "inhale_exhale_ratio"], value)}
                                value={content?.data?.breathing_pattern?.inhale_exhale_ratio || ''}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="title text-uppercase mt-5 mb-1">
                    Mental & Emotional Well-being
                    </div>
                    <div className="text-muted">How do you approach and manage recurring patterns or triggers?</div>
                    {
                        YogaArrivalConsultationField?.mentalEmotionalWellbeing?.map((item, index)=>{
                            return (
                                <div className='row'>
                                    <div className="col-4 mt-3 fw-semi-bold">{item.label}</div>
                                    <div className="col-2 mt-3">
                                    <CheckBox
                                            initialchecked={content?.data?.mental_emotional_wellbeing?.[item.key]==='Yes'}
                                            onChange={(e)=>{setKey(["mental_emotional_wellbeing", item.key], content?.data?.mental_emotional_wellbeing?.[item.key]==='No' ? "Yes": "No")}}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='mt-3 row'>
                        <div className='fw-semi-bold col-4'> Any other points to share</div>
                        <div className="col-8">
                            <TextArea
                                row={3}
                                placeholder={"Enter "}
                                onChange={value =>setKey(["mental_emotional_wellbeing", "other_points"], value)}
                                value={content?.data?.mental_emotional_wellbeing?.other_points || ''}/>
                        </div>
                    </div>

                </div>
            </div>
            <Objectives
                content={content}
                removeObjective={removeObjective}
                setKey={setKey}
                handleAddMore={handleAddMore}
            />
          </>
          <div className="w-50">
          {uploadComponent}
          </div>
      </div>
    )
}