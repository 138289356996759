import moment from 'moment'
import { YogaArrivalConsultationField } from '../NoteFormsConstants'
import {ReactComponent as CheckedIcon} from '../../../../assets/images/checked.svg'
import {ReactComponent as UncheckedIcon} from '../../../../assets/images/unchecked.svg'
import React from 'react'


export const ViewYogaArrivalConsultation = ({content})=>{
    return (
        <div className="my-3 ">
            <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                <div className='d-flex'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{content.conducted_by || '-'}</div>
                </div>
                <div className='d-flex  w-25'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            <div className='border rounded overflow-hidden'>
                <div className='p-3'>
                    <div className='mb-4'>
                        <div className='fw-semibold mb-1'>Knowledge/experience level of yoga and pranayama. If yes, frequency of practice. </div>
                        <div>{content?.data?.experience_in_yoga_pranayama || '-'}</div>
                    </div>
                    <div className='mb-4'>
                        <div className='fw-semibold mb-1'>Knowledge/experience level of meditation/mindfullness. If yes, frequency of practice. </div>
                        <div>{content?.data?.experience_in_meditation || '-'}</div>
                    </div>
                    <div className='mb-4'>
                        <div className='fw-semibold mb-1'>Forms of activity for physical fitness. Frequency </div>
                        <div>{content?.data?.experience_in_meditation || '-'}</div>
                    </div>
                    <hr />
                    <div className='my-3'>
                        <div className='fw-semibold mb-3 text-uppercase text-secondary'>Specific Areas of Discomfort: </div>
                        <div className='row mb-3'>
                            <div className="col-4"></div>
                            <div className="col-2"></div>
                            <div className="col-3 text-center text-muted fw-semi-bold">Frequency of discomfort</div>
                            <div className="col-3 text-center text-muted fw-semi-bold">Known reasons</div>
                        </div>
                        <div>
                        {
                            YogaArrivalConsultationField?.areaOfDiscomfort?.map((item, index)=>(
                                <div className='mb-2 row' key={index}>
                                    <div className="col-4 mt-3 fw-semi-bold">{item.label}</div>
                                    <div className="col-2 text-center mt-3">{content?.data?.area_of_discomfort?.[item.key]?.value || "No"}</div>
                                    <div className="col-3 text-center mt-3">{content?.data?.area_of_discomfort?.[item.key]?.frequency || '-'}</div>
                                    <div className="col-3 text-center mt-3">{content?.data?.area_of_discomfort?.[item.key]?.reasons || '-'}</div>
                                </div>
                                ))
                        }
                        </div>
                    </div>
                    <hr />
                    <div className='mt-4'>
                        <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Yogic Assessment: </div>
                        <div className='row mb-3'>
                            <div className="col-4"></div>
                            <div className="col-2  text-center text-muted fw-semi-bold">Beginner</div>
                            <div className="col-2 text-center text-muted fw-semi-bold">Intermediate</div>
                            <div className="col-2 text-center text-muted fw-semi-bold">Advanced</div>
                        </div>
                        <div>
                        {
                            YogaArrivalConsultationField?.yogicAssessment?.map((item, index)=>(
                                    <div className='mb-2 row' key={index}>
                                        <div className="col-4 mt-2  fw-semi-bold">{item.label}</div>
                                        <div className="col-2 mt-2 text-center">{content?.data?.yogic_assessment?.[item.key]==='beginner' ? <CheckedIcon/> : <UncheckedIcon/> }</div>
                                        <div className="col-2 mt-2 text-center">{content?.data?.yogic_assessment?.[item.key]==='intermediate' ? <CheckedIcon/> : <UncheckedIcon/> }</div>
                                        <div className="col-2 mt-2 text-center">{content?.data?.yogic_assessment?.[item.key]==='advanced' ? <CheckedIcon/> : <UncheckedIcon/> }</div>
                                    </div>
                                ))
                        }
                        </div>
                    </div>
                    <hr />
                    <div className='mt-4'>
                        <div className='fw-semibold mb-3  text-secondary text-uppercase'>Breathing Pattern: </div>
                        <div>
                        {
                            YogaArrivalConsultationField?.breathingPatterns?.map((item, index)=>(
                                <div className='mb-2 row' key={index}>
                                    <div className="col-4 mt-1  fw-semi-bold">{item.label}</div>
                                    <div className="col-8 mt-2 ">{content?.data?.breathing_pattern?.[item.key] || '-'}</div>
                                </div>
                            ))
                        }
                        </div>
                        <div className='mb-2 row'>
                            <div className="col-4 mt-2  fw-semi-bold"> Inhale : Exhale Ratio</div>
                            <div className="col-8 mt-2 ">{content?.data?.breathing_pattern?.inhale_exhale_ratio|| '-' }</div>

                        </div>
                    </div>
                    <hr/>
                    <div className='mt-4'>
                        <div className='fw-semibold text-secondary text-uppercase'>Mental & Emotional Well-being: </div>
                        <div className="text-muted mb-3">How do you approach and manage recurring patterns or triggers?</div>
                        <div>
                        {
                            YogaArrivalConsultationField?.mentalEmotionalWellbeing?.map((item, index)=>(
                                <React.Fragment key={index}>
                                    <div className='mb-2 row' key={index}>
                                        <div className="col-4 mt-1  fw-semi-bold">{item.label}</div>
                                        <div className="col-8 mt-2 ">{content?.data?.mental_emotional_wellbeing?.[item.key] || '-'}</div>
                                    </div>
                                </React.Fragment>
                                ))
                        }
                        </div>
                        <div className='mb-2 row'>
                            <div className="col-4 mt-2  fw-semi-bold"> Any other points to share</div>
                            <div className="col-8 mt-2 ">{content?.data?.mental_emotional_wellbeing?.other_points|| '-' }</div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center py-2 fw-semibold">
                SUMMARY ASSESSMENT AND TREATMENT PLAN
                </div>
                <div className='m-3'>
                    <div>
                    <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                    {
                            content?.data?.summary_assessment_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className='border mb-4' key={index}>
                                        <div className='fw-semibold py-2 text-center bg-light text-uppercase'> Objective {(index+1)?.toString()?.padStart(2,0)}: </div>
                                        <table className='p-2 w-100'>
                                        <tbody>
                                            {
                                                YogaArrivalConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <tr className='d-flex' key={subIndex}>
                                                        <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}</td>
                                                        <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.summary_assessment_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                    </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                )
                            })
                        }
                        <div className='ms-3'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
