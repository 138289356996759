import React, {memo, useEffect, useState } from 'react'
import './TextArea.scss'

function TextArea({name, value, placeholder, heading, onChange, row}) {
  const [text, setText] = useState(value)

  useEffect(() => {
    if(value?.length)
      onChange?.(value)
  }, [])


  return (
    <div className='text-area'>
      {heading && <div className="heading my-2 mt-3">{heading}</div>}
      <div className="input">
        <textarea  
          name={name} 
          placeholder={placeholder}
          onChange={(e)=>{
            setText(e.target.value);
            onChange(e.target.value)
          }}
          rows={row || 4}
          value={text}
        >
        
        </textarea>
      </div>
    </div>
  )
}

export default memo(TextArea);